import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {Product} from '../Product.types';
import InitialChoices from './InitialChoices';
import UpdatedChoices from './UpdatedChoices';

type props = {
  choices: Product[];
  onNext: () => void;
  onValidationChanged: (isValid: boolean) => void;
  selectedProduct: string;
  setSelectedProduct: (priceId: string) => void;
  useUpdated: boolean;
};

const Choices: FC<props> = ({
  choices,
  onNext,
  onValidationChanged,
  selectedProduct,
  setSelectedProduct,
  useUpdated,
}) => {
  const onSelectProduct = (priceId: string) => {
    setSelectedProduct(priceId);
    onValidationChanged(true);

    // Record analytics event
    window.analytics.track('Onboarding SelectProduct', {
      priceId,
      title: choices?.find(choice => choice.priceId === priceId)?.title,
    });

    onNext();
  };

  return (
    <Box>
      {useUpdated && (
        <UpdatedChoices
          choices={choices}
          onSelectProduct={onSelectProduct}
          selectedProduct={selectedProduct}
        />
      )}
      {!useUpdated && (
        <InitialChoices
          choices={choices}
          onSelectProduct={onSelectProduct}
          selectedProduct={selectedProduct}
        />
      )}
    </Box>
  );
};

export default Choices;
