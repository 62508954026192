const shareBodyMessage = `We’ve been using a reading app called Ello that we’re really loving. If you sign up with this link, you can get your first month free: `;

async function copyLinkToClipboard(code: string): Promise<boolean> {
  try {
    if (!navigator?.clipboard) {
      return false;
    }

    const link = referralLink(code);

    await navigator.clipboard.writeText(link);
    return true;
  } catch (e) {
    console.error('failed to copy to clipboard', e);
    return false;
  }
}

function referralLink(code: string): string {
  if (!code) {
    return '';
  }

  const baseURL = 'https://ello.com/r';
  const link = `${baseURL}/${code}`;
  return link;
}

function canShareLink(): boolean {
  return !!navigator?.share;
}

async function copyLinkAndShare(code: string): Promise<boolean> {
  try {
    if (!canShareLink()) {
      return false;
    }

    const link = referralLink(code);

    await navigator.share({
      title: 'Read with Ello',
      text: shareBodyMessage,
      url: link,
    });
    return true;
  } catch (e) {
    console.error('failed to copy link to share', e);
    return false;
  }
}

export const ReferralLinkService = {
  canShareLink,
  copyLinkAndShare,
  copyLinkToClipboard,
  referralLink,
};
