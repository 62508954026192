import React from 'react';

import Typography from '@mui/material/Typography';
import * as palette from 'shared/styles/palette';

const SupportText = () => {
  return (
    <Typography
      style={{
        fontWeight: 500,
        color: palette.gray700,
        fontSize: '12px',
        marginTop: '1rem',
      }}>
      Your child’s reading ability was determined by answers you provided upon
      adding a child profile. <br /> If you have any questions regarding your
      child's level you can reach us via email{' '}
      <Typography
        component="b"
        style={{
          fontWeight: 700,
          color: palette.turquoise900,
          fontSize: '12px',
        }}>
        support@helloello.com
      </Typography>{' '}
      or text{' '}
      <Typography
        component="b"
        style={{fontWeight: 700, color: palette.blue500, fontSize: '12px'}}>
        (415) 214-8119
      </Typography>{' '}
    </Typography>
  );
};

export default SupportText;
