import {supportsDigital} from '../../utils/productType';
import {
  ONBOARDING_MUTATION,
  ONBOARD_DIGITAL_CUSTOMER_MUTATION,
  PURCHASE_COUPON_MUTATION,
} from './PaymentPanel.query';

export const PaymentAction = {
  PURCHASE_COUPON: 'purchase-coupon',
};

export function getMutation(action, productType) {
  if (action === PaymentAction.PURCHASE_COUPON) {
    return {
      mutation: PURCHASE_COUPON_MUTATION,
      supportsTrials: false,
    };
  }

  if (supportsDigital(productType)) {
    return {
      mutation: ONBOARD_DIGITAL_CUSTOMER_MUTATION,
      supportsTrials: true,
    };
  }

  return {
    mutation: ONBOARDING_MUTATION,
    supportsTrials: false,
  };
}
