import React from 'react';
import {Typography, Grid, Box} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Button, {ButtonType} from 'shared/components/common/Button';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import {ReactComponent as SubscriptionCancelledSVG} from 'shared/assets/subscriptionCancelled.svg';
import styles from './CancellationConfirmed.styles';

interface Props extends WithStyles<typeof styles> {
  onConfirm: () => void;
  onBack?: () => void;
  hideDesktopBackButton?: boolean;
}

const CancellationConfirmed = ({
  classes,
  onConfirm,
  onBack = () => {},
  hideDesktopBackButton = false,
}: Props) => {
  const isMobile = useMobileLayout();

  return (
    <>
      <Grid container className={classes.content}>
        <Box className={classes.topContainer}>
          <Typography variant={'h6'} align={'center'} className={classes.title}>
            We're sorry to see you go and hope you enjoyed using Ello
          </Typography>

          <Box>
            <SubscriptionCancelledSVG className={classes.icon} />
          </Box>
          <Typography className={classes.subtext}>
            If you wish to re-subscribe in the future, please reach out to
            support@helloello.com
          </Typography>
        </Box>
        <Box>
          <Grid container className={classes.buttonContainer}>
            {isMobile ? (
              <>
                <Button className={classes.button} onClick={onConfirm}>
                  OK
                </Button>
              </>
            ) : (
              <>
                <Grid container className={classes.leftContainer}>
                  <Grid item>
                    {!hideDesktopBackButton && (
                      <Button
                        className={classes.activeButton}
                        variant={ButtonType.Inline}
                        onClick={onBack}>
                        Back
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Button className={classes.button} onClick={onConfirm}>
                    OK
                  </Button>
                </Grid>
                <Grid container className={classes.rightContainer}></Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CancellationConfirmed);
