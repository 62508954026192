import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import * as palette from 'shared/styles/palette';
import {
  LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../../../../shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    profileCardContainer: {
      width: '100%',
      marginRight: 0,
      marginBottom: '1.5rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        maxWidth: '350px',
        marginRight: '1.5rem',
      },
    },
    profilesScreenContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginTop: '1.5rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        flexDirection: 'row',
      },
    },
    profileIconNameContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      border: `1px solid ${palette.gray100}`,
      borderBottom: 0,
      borderRadius: '1rem 1rem 0 0',
      padding: '1.5rem',
    },
    profileNameContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        flexDirection: 'column',
      },
    },
    profileCharacter: {
      borderRadius: '50%',
      flex: 1,
      maxWidth: '30%',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        maxWidth: '60%',
      },
    },
    profileName: {
      flex: 2,
      fontSize: '1.1rem',
      marginLeft: '1rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginTop: '0.5rem',
      },
    },
    settingsIcon: {
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        display: 'flex',
        alignSelf: 'flex-start',
      },
    },
    header: {
      title: {
        fontSize: '1.625rem',
      },
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    addChildButton: {
      fontWeight: 700,
      fontSize: theme.spacing(2),
      color: palette.turquoise900,
      textTransform: 'none',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        color: palette.white,
        backgroundColor: palette.turquoise900,
        '&:hover': {
          backgroundColor: palette.turquoise900,
        },
        borderRadius: '2rem',
        padding: '0.6rem 1.5rem',
      },
    },
    addChildIcon: {
      marginRight: '0.5rem',
    },
    maxProfilesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '80vh',
      padding: '1rem',
      flexDirection: 'column',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        height: '100%',
      },
    },
    maxProfilesHeader: {
      color: palette.blue500,
      fontWeight: 800,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      textAlign: 'center',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginBottom: theme.spacing(2),
      },
    },
    maxProfilesImg: {
      marginBottom: theme.spacing(6),
    },
    maxProfilesSubtitle: {
      color: palette.blue500,
      fontWeight: 500,
      fontSize: '1.2rem',
      lineHeight: '2rem',
      textAlign: 'center',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        fontWeight: 400,
        color: palette.gray500,
        lineHeight: '1rem',
      },
    },
    maxProfilesButton: {
      backgroundColor: palette.turquoise900,
      width: '100%',
      color: palette.white,
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      borderRadius: '2rem',
      fontWeight: 800,
      fontSize: '1rem',
      textTransform: 'none',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        width: '200px',
      },
    },
    modalBox: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 800,
      width: '80vw',
      borderRadius: '1em',
      backgroundColor: palette.white,
      padding: '1rem',
    },
    closeIconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    maxProfilesHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        flexDirection: 'column-reverse',
      },
    },
    moreInfoIcon: {
      cursor: 'pointer',
    },
    readingLevelContainer: {
      backgroundColor: palette.turquoise200,
      padding: '1rem',
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      border: `1px solid ${palette.gray100}`,
      borderBottom: 0,
      borderRadius: '0 0 1rem 1rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        padding: '1.2rem',
      },
    },
    readingLevelTextContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    readingLevelText: {
      color: palette.turquoise900,
      fontWeight: 400,
      marginRight: 5,
      display: 'inline',
    },
    tooltipText: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: palette.gray700,
    },
    tooltipSupportText: {
      fontWeight: 600,
      color: palette.turquoise900,
      fontSize: '0.875rem',
    },
    tooltipNumber: {
      fontWeight: 600,
      color: palette.blue500,
      fontSize: '0.875rem',
    },
    closeIcon: {
      fontSize: '2rem',
    },
    requestAdjustmentButton: {
      padding: '0.8rem',
      color: palette.blue500,
      border: `1px solid ${palette.blue500}`,
      borderRadius: '50px',
      fontWeight: 800,
      fontSize: '0.7rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginInline: '0.5rem',
      },
      [theme.breakpoints.up(LAPTOP_BREAKPOINT)]: {
        marginInline: '1rem',
        fontSize: '1rem',
        padding: '0.8rem 1.5rem',
      },
    },
    requestAdjustmentButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '3rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        justifyContent: 'flex-start',
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    navigationButton: {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
      borderRadius: 50,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      minWidth: 150,
      maxWidth: 300,
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: 1.25,
      paddingTop: 15,
      paddingBottom: 15,
    },
    adjustmentRadioButton: {
      textAlign: 'center',
      padding: '0.8rem',
      marginTop: theme.spacing(2),
      color: palette.blue500,
      border: `1px solid ${palette.gray100}`,
      borderRadius: '16px',
      fontWeight: 800,
      fontSize: '0.7rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginInline: '0.5rem',
      },
      [theme.breakpoints.up(LAPTOP_BREAKPOINT)]: {
        marginInline: '1rem',
        fontSize: '1rem',
        padding: '0.8rem 1.5rem',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
    adjustmentRadioButtonSelected: {
      backgroundColor: 'rgba(0, 150, 136, 0.1)',
      border: `1px solid ${palette.blue500}`,
      '&:hover': {
        backgroundColor: 'rgba(0, 150, 136, 0.2)',
      },
    },
    levelSelectionLink: {
      color: palette.turquoise900,
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '16px',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
  });

export default styles;
