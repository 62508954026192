import React from 'react';
import {useState} from 'react';
import {TextField} from '@mui/material';
import firebase from 'firebase/app';
import {LoginStep} from '../constants';
import {IS_VALID_PHONE_QUERY} from 'customerPortal/screens/CustomerLoginScreen/LoginPhoneNumber/LoginPhoneNumber.query';
import {useLazyQuery} from '@apollo/react-hooks';
import validateNumberAndProceedToAuth from 'customerPortal/utils/phoneValidator';
import CustomerLoginScreenForm from '../CustomerLoginScreen.form';

interface Props {
  setError: (e: string) => void;
  setIsLoading: (l: boolean) => void;
  setCurrentStep: (s: LoginStep) => void;
}

const LoginPhoneNumber = ({setError, setIsLoading, setCurrentStep}: Props) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const [queryPhoneValidation] = useLazyQuery(IS_VALID_PHONE_QUERY, {
    fetchPolicy: 'no-cache',
    onError: error => console.error('Phone validation::', error),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPhoneNumber(e.target.value);
  };

  const requestAuthCode = async (phoneNumber: string) => {
    const appVerifier = window.recaptchaVerifier;

    if (!appVerifier) {
      console.error('No recaptchaVerifier found');
      return;
    }

    try {
      window.confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier);
      setCurrentStep(LoginStep.VerifyStep);
    } catch (error) {
      console.error('Phone number::', error);
      setError((error as any).message);
    }
    setIsLoading(false);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateNumberAndProceedToAuth(
      phoneNumber,
      setIsLoading,
      setError,
      queryPhoneValidation,
      requestAuthCode,
    );
  };

  return (
    <>
      <CustomerLoginScreenForm onSubmit={onSubmit}>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          id="phone"
          label="Phone Number"
          name="phone"
          type="tel"
          autoComplete="tel"
          placeholder="1234567890"
          value={phoneNumber}
          onChange={onChange}
          inputProps={{'data-testid': 'loginFormInput'}}
        />
      </CustomerLoginScreenForm>
    </>
  );
};

export default LoginPhoneNumber;
