import {gql} from '@apollo/client';

export const QUERY_PROFILE_BOOKS = gql`
  query ProfileBooks($profileId: ID!, $bookType: String) {
    profileBooks(profileId: $profileId, bookType: $bookType) {
      id
      title
      author
      coverPhotoURL
      level
    }
  }
`;
