import {collectQueryParams} from '../../utils/queryParams';

async function copyLinkToClipboard(link: string): Promise<boolean> {
  try {
    if (!navigator?.clipboard) {
      return false;
    }

    await navigator.clipboard.writeText(link);
    return true;
  } catch (e) {
    console.error('failed to copy to clipboard', e);
    return false;
  }
}

function referralLink(baseUrl?: string): string | undefined {
  const params = collectQueryParams() as {referralCode?: string};
  if (!params.referralCode) {
    return;
  }

  const base = baseUrl || 'https://ello.com/r';

  return `${base}/${params.referralCode}`;
}

export const ReferralLinkService = {
  copyLinkToClipboard,
  referralLink,
};
