import {LoginErrorMessage} from 'customerPortal/screens/CustomerLoginScreen/constants';
import {formatToUSNumber, PHONE_NUMBER_REGEXP} from 'shared/utils/numberUtils';

interface QueryVariables {
  variables: {
    phoneNumber: string;
  };
}

interface QueryResult {
  data: {
    validatePhoneUser: boolean;
  };
}

const validateNumberAndProceedToAuth = async (
  phoneNumber: string,
  setIsLoading: (l: boolean) => void,
  setError: (e: any) => void,
  queryPhoneValidation: (variables: QueryVariables) => Promise<QueryResult>,
  requestAuthCode: (phoneNumber: string) => Promise<void>,
) => {
  setIsLoading(true);

  const sanitizedNumber = phoneNumber.replace(/[^0-9+]/g, '');
  const isValidPhone = PHONE_NUMBER_REGEXP.test(sanitizedNumber);

  if (!isValidPhone) {
    setError(LoginErrorMessage.InvalidNumber);
    setIsLoading(false);
    return;
  }

  try {
    const formattedNumber = formatToUSNumber(phoneNumber);
    const {data: queryResult} = await queryPhoneValidation({
      variables: {
        phoneNumber: formattedNumber,
      },
    });

    if (!queryResult.validatePhoneUser) {
      setError(LoginErrorMessage.UnregisteredNumber);
      setIsLoading(false);
      return;
    }

    await requestAuthCode(formattedNumber);
    setIsLoading(false);
  } catch (e) {
    console.error('Validation::', e);
    setError(LoginErrorMessage.ApiError);
    setIsLoading(false);
  }
};

export default validateNumberAndProceedToAuth;
