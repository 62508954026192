/*
 * A payment panel with stripe credit card components.
 * PaymentRequestButtonElement only renders on HTTPS!
 *
 * - Expects all the finalized data in the URL query params.
 * - If the `priceId` URL param is present expects 'priceId' to be an index into
 *   productDetails props, i.e.
 *   productDetails: {
 *     'price_1H4Z2pJZ2Z2Z2Z2Z2Z2Z2Z2Z2': {
 *       title: 'First product title',
 *       ...
 *     },
 *    'price_1H4Z2pANOTHERPRIZE': {
 *       title: 'Second product title',
 *       ...
 *     },
 *   }
 * - If the `product` URL param is not present, expects 'productDetails' to be an object, i.e.
 *   productDetails: { title: 'Product title', ... }
 */

import {withApollo} from '@apollo/client/react/hoc';
import {HighlightOff} from '@mui/icons-material';
import {Icon, IconButton, Paper} from '@mui/material';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from 'react';
import padlock from 'shared/assets/icons/padlock.svg';
import quotation from 'shared/assets/icons/quotation.svg';
import stars from 'shared/assets/icons/stars.svg';
import poweredByStripe from 'shared/assets/poweredByStripe.svg';
import ApplePayField from 'shared/components/common/ApplePayField';
import CreditCardField from 'shared/components/common/CreditCardField';
import MoneyBackBadge from 'shared/components/common/MoneyBackBadge/MoneyBackBadge';
import {LAPTOP_BREAKPOINT, TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {collectQueryParams} from 'shared/utils/queryParams';
import {QueryParams, StringParam} from 'use-query-params';
import {SlackMessageTypes, sendSlackMessage} from '../../../shared/utils/slack';
import {withDebounce} from '../../utils/debounce';
import {CustomerProductType, supportsDigital} from '../../utils/productType';
import ElloAppSubscription from './ElloAppSubscription';
import {ESTIMATE_TAX, VALIDATE_COUPON} from './PaymentPanel.query';
import {PaymentAction, getMutation} from './PaymentPanel.service';
import styles from './PaymentPanel.styles';
import Total from './PaymentPanel.total';
import TrialCompliance from './TrialCompliance';
import {ErrorBoundary} from 'react-error-boundary';

const STRIPE_KEY =
  process.env.NODE_ENV === 'production' &&
  !(
    window.location.href.includes('staging') ||
    window.location.href.includes('localhost') ||
    window.location.href.includes('webflow.io') ||
    window.location.href.includes('rav')
  )
    ? 'pk_live_RfrSw6WcCh2q9OfNA5l7uFZt00Qe3RDL6Z'
    : 'pk_test_woKjziuu3AMwlJg2EERTa00X00p97XUOTC';

const stripePromise = loadStripe(STRIPE_KEY);

const queryParams = {coupon: StringParam};

class PaymentPanel extends React.Component {
  constructor(props) {
    super(props);

    // Get product from query params and index into product details if priceId specified
    const queryParams = collectQueryParams();

    const priceId = queryParams.priceId;
    const productDetails = !!priceId
      ? props.productDetails[priceId]
      : props.productDetails;

    const isValidShipping =
      productDetails &&
      productDetails.shipping !== undefined &&
      parseInt(productDetails.shipping) !== 0;

    this.state = {
      stripe: null,
      error: null,
      errorVisible: false,
      loading: false,
      validatingCoupon: false, // used to keep track of when we are validating the coupon
      couponVisible: false,
      couponError: '', // Error for the coupon field
      couponTitle: '', // Successful coupon title, if we were able to get it!
      showShipping: isValidShipping,
      disableCoupon:
        !!props.disableCoupon || props.action === PaymentAction.PURCHASE_COUPON, // Do not support coupon codes for coupon purchase
      total: 0,
      discount: 0,
      tax: 0,
      isTablet: window.matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`)
        .matches,
      isDesktop: window.matchMedia(`(min-width: ${LAPTOP_BREAKPOINT}px)`)
        .matches,
      paymentInputComplete: false, // used to track if all payment information has been entered
      coupon: '',
      isApplePayReady: false,
    };

    if (!!productDetails) {
      this.priceString = productDetails.price;
      this.titleString = productDetails.title;
      this.shipping = productDetails.shipping | 0;
      this.features = productDetails.features;
      this.action = props.action;
      this.productType =
        productDetails.productType || CustomerProductType.physicalSubscription;
      this.trialPeriodDays = productDetails.trialPeriodDays || 0;
      this.chargeableTrialAmount = queryParams.chargeableTrialAmount;
    } else {
      if (priceId) {
        console.log(
          'Error: priceId was specified but no product details for this priceId found.',
        );
      }
      this.state.error =
        'An error occured. Please contact us at (415) 214-8119.';
      this.state.errorVisible = true;
    }

    this.creditCardField = React.createRef();
    this.couponField = React.createRef();
  }

  tabletMediaHandler = e => this.setState({isTablet: e.matches});
  desktopMediaHandler = e => this.setState({isDesktop: e.matches});

  componentDidMount() {
    // update total
    this.calculateTotal();
    // update width and height
    window
      .matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`)
      .addEventListener('change', this.tabletMediaHandler);

    window
      .matchMedia(`(min-width: ${LAPTOP_BREAKPOINT}px)`)
      .addEventListener('change', this.desktopMediaHandler);

    // If a Rewardful coupon is specified, but no coupon is on the query param, we inherit it;
    // that is, a user-specified or query-specified coupon overwrites the Rewardful-specified one.
    if (window.rewardful) {
      window.rewardful('ready', () => {
        if (window.Rewardful.coupon) {
          this.setState({coupon: window.Rewardful.coupon, couponVisible: true});
          this.handleCoupon(window.Rewardful.coupon);
        }
      });
    }

    // Trigger coupon validation and confirmation animation if the coupon field was given
    // a value from the query string
    if (this.couponField.current && this.couponField.current.value) {
      this.setState({couponVisible: true});
      this.handleCoupon(this.couponField.current.value, null);
    }

    // If a productDetail coupon is specified then use it.
    // TODO: Confirm that this is the right behavior.
    if (this.props.productDetails?.coupon && !this.state.disableCoupon) {
      this.setState({
        coupon: this.props.productDetails.coupon,
        couponVisible: true,
      });
      this.handleCoupon(this.props.productDetails.coupon);
    }
  }

  componentWillUnmount() {
    window
      .matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`)
      .removeEventListener('change', this.mediaHandler);
  }

  updateWindowDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  };

  applePayReady = isReady => {
    this.setState({isApplePayReady: isReady});
  };

  logError = error => {
    const message = `An error was encountered by the Stripe payment element: \n\nURL: ${window.location.href} \n\nError: ${error.message}`;
    sendSlackMessage(SlackMessageTypes.OnboardingError, message);
  };

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({errorVisible: false});
  };

  /**
   * Triggers payment
   */
  performAction = withDebounce(() => {
    window.analytics.track('Onboarding Pay', {
      productType: this.productType,
      estimatedAmountPaid: this.state.total * 100, // convert to cents
    });
    this.setState({loading: true});
    this.creditCardField.current.processPayment();
  }, 200);

  /**
   * Callback handler for stripe payment: triggers our onboarding API call.
   */
  handlePayment = result => {
    if (result.error) {
      // Track error of any kind (note that success gets tracked on the backend side)
      window.analytics.track('Onboarding PaymentError', {
        error: result.error.message,
      });
      this.setState({loading: false});
      this.setState({error: result.error.message, errorVisible: true});
      return;
    }

    // Connect analytics events (bubblewrap for ad blocker)
    let anonymousId;
    try {
      anonymousId = window.analytics.user().anonymousId();
    } catch (e) {
      console.warn(e);
    }

    const {action, flowName, stripePriceId} = this.props;
    if (action && action !== PaymentAction.PURCHASE_COUPON) {
      // Action specified but not defined
      this.setState({
        error: 'Oops – something went wrong!',
        errorVisible: true,
      });
      console.log(`Error: '${action}' is not a valid payment action`);

      return;
    }

    const {mutation, supportsTrials} = getMutation(action, this.productType);
    // Capture coupon if there is any
    const params = collectQueryParams();
    const inputVariables = {
      // 'product' URL query param overwrites stripe price ID
      // (Note, we're effectively allowing users to specify any price ID here on the frontend)
      stripePriceId: params.priceId || stripePriceId,
      flowName: flowName,
      phone: params.phone,
      paymentMethod: result.paymentMethod.id, // we just captured with stripe
      content: params, // may want to remove the ones we're already sending so we don't double
      name: params.name,
      email: params.email,
      coupon: this.couponField?.current?.value || params.coupon || undefined,
      uid: anonymousId,
      referral: !!window.Rewardful ? window.Rewardful.referral : undefined,
      productType: this.productType,
      stripeBillingPostalCode:
        result.paymentMethod.billing_details.address.postal_code,
    };

    if (supportsTrials) {
      inputVariables.trialPeriodDays = this.trialPeriodDays;
    }

    if (params.transaction_id) {
      inputVariables.tuneTransactionId = params.transaction_id;
    }

    if (this.state.disableCoupon) {
      inputVariables.coupon = '';
    }

    if (params.isbns) {
      inputVariables.isbns = params.isbns;
    }

    if (params.referredBy) {
      inputVariables.referralCode = params.referredBy;
    }

    this.props.client
      .mutate({mutation, variables: inputVariables})
      .catch(error => {
        this.setState({loading: false});

        this.setState({
          // A bit hacky, but for now we want to be pretty generous with showing all errors that occur during onboarding
          // so that our beta customers can help us debug!
          error: error.message.replaceAll('GraphQL error: ', ''),
          errorVisible: true,
        });
        console.log('Error calling API:', error);
      })
      .then(onboardingResult => {
        if (onboardingResult) {
          const {onboarding, onboardDigitalCustomer, purchaseCoupon} =
            onboardingResult.data;

          this.props.onConfirm(
            onboarding || onboardDigitalCustomer || purchaseCoupon,
            {
              email: params.email,
              phone: params.phone,
              productType: this.productType,
            },
          );
        }
        this.setState({loading: false});
      });
  };

  /**
   * Coupon field onChange handler.
   *
   * value: value of the field
   * setQuery: query update handler, omitted on first page load call
   */
  handleCoupon = (value, setQuery) => {
    if (this.state.disableCoupon) {
      return;
    }
    this.setState({couponError: '', validatingCoupon: true});

    const params = collectQueryParams();
    const {stripePriceId} = this.props;
    const priceId = params.priceId || stripePriceId;

    if (value) {
      this.props.client
        .query({
          query: VALIDATE_COUPON,
          variables: {coupon: value, priceId},
        })
        .then(({data}) => {
          this.couponField.current.blur();
          if (setQuery) setQuery({coupon: value});
          this.setState({
            couponError: '',
            couponTitle: data.validateCoupon.name,
            validatingCoupon: false,
          });
          this.calculateTotal(
            data.validateCoupon.amountOff,
            data.validateCoupon.percentOff,
          );
        })
        .catch(error => {
          if (error.toString().includes('Coupon Expired')) {
            this.setState({
              couponError: 'Coupon has expired',
              couponTitle: '',
              validatingCoupon: false,
            });
          } else {
            this.setState({
              couponError: 'Coupon not valid',
              couponTitle: '',
              validatingCoupon: false,
            });
          }
          this.calculateTotal();
          if (setQuery) setQuery({coupon: ''});
        });
    } else {
      if (setQuery) setQuery({coupon: ''});
      this.setState({
        couponError: '',
        couponTitle: '',
        validatingCoupon: false,
      });
      this.calculateTotal();
    }
  };

  estimateTax = total => {
    const params = collectQueryParams();

    // Sometimes we don't  collect shipping address
    // in those cases don't estimate tax
    const hasNoShippingAddress =
      !params.address &&
      !params.address_city &&
      !params.address_state &&
      !params.address_zip;

    if (hasNoShippingAddress) {
      return;
    }

    const {stripePriceId} = this.props;
    const priceId = params.priceId || stripePriceId;

    const totalAmount = isNaN(total) ? 0 : total * 100;
    const amount = parseInt(Number(totalAmount).toFixed(0));

    this.props.client
      .query({
        query: ESTIMATE_TAX,
        variables: {
          amount,
          line1: params.address,
          city: params.address_city,
          state: params.address_state,
          postalCode: params.address_zip,
          priceId: priceId,
        },
      })
      .then(({data}) => {
        // amountTotal is inclusive of tax
        const totalAmount = data.estimateTax.amountTotal / 100;
        const taxEstimate = data.estimateTax.taxAmountExclusive
          ? data.estimateTax.taxAmountExclusive / 100
          : data.estimateTax.taxAmountInclusive / 100;
        this.setState({
          total: parseFloat(Number(totalAmount).toFixed(2)),
          tax: parseFloat(Number(taxEstimate).toFixed(2)),
        });
      })
      .catch(error => {
        if (error.message.includes('No such price')) {
          const message = `An error was encountered by a user while loading price during onboarding: \n\npriceId: ${priceId} \nURL: ${window.location.href}`;
          sendSlackMessage(SlackMessageTypes.OnboardingError, message);
        }
        this.setState({
          error: error.message,
          errorVisible: true,
        });
      });
  };

  calculateTotal = (amountOff, percentOff) => {
    if (this.chargeableTrialAmount) {
      this.setState({total: this.chargeableTrialAmount});
      return;
    }
    if (!amountOff && !percentOff) {
      const total = (Number(this.priceString) + Number(this.shipping)).toFixed(
        2,
      );
      this.setState({total});
      this.estimateTax(total);
    } else if (amountOff) {
      const price = Number(this.priceString) - amountOff / 100;
      const total = (price + Number(this.shipping)).toFixed(2);
      this.setState({total, discount: (amountOff / 100).toFixed(2)});
      this.estimateTax(total);
    } else if (percentOff) {
      const total = (Number(this.priceString) + Number(this.shipping)).toFixed(
        2,
      );
      const discountedTotal = (total * ((100 - percentOff) / 100)).toFixed(2);
      this.setState({
        total: discountedTotal,
        discount: (total * (percentOff / 100)).toFixed(2),
      });
      this.estimateTax(discountedTotal);
    }
  };

  complete = isComplete => this.setState({paymentInputComplete: isComplete});

  render() {
    const {classes} = this.props;

    const trialTotal = this.chargeableTrialAmount
      ? this.chargeableTrialAmount
      : '0';

    const amountToCharge = this.trialPeriodDays ? trialTotal : this.state.total;
    const parsedAmount = parseFloat(amountToCharge);
    const validAmountToCharge = isNaN(parsedAmount) ? 0 : parsedAmount;
    const stripeAmount = Math.round(validAmountToCharge * 100);

    const stripeElementOptions = {
      mode: 'subscription',
      amount: stripeAmount,
      currency: 'usd',
      paymentMethodCreation: 'manual',
    };

    const creditCardField = (
      <CreditCardField
        ref={this.creditCardField}
        complete={this.complete}
        onPayment={this.handlePayment}
        isApplePayReady={this.state.isApplePayReady}
      />
    );

    const stripeElements = (
      <Elements stripe={stripePromise} options={stripeElementOptions}>
        <ApplePayField
          onPayment={this.handlePayment}
          applePayReady={this.applePayReady}
        />
        {creditCardField}
      </Elements>
    );

    const fallbackStripeElements = (
      <Elements stripe={stripePromise}>{creditCardField}</Elements>
    );

    const stripeProviderSection = (
      <div className={classes.stripeSectionContainer}>
        <ErrorBoundary
          fallback={fallbackStripeElements}
          onError={this.logError}>
          {stripeElements}
        </ErrorBoundary>
      </div>
    );

    const testimonial = (
      <Paper elevation={0} className={classes.testimonialSection}>
        <Box display="flex" flexDirection="row">
          <Box>
            <img
              src={quotation}
              alt="Quotation mark"
              className={classes.quotationMark}
            />
          </Box>
          <Box>
            <Typography className={[classes.testimonial]}>
              My son just finished all his books, asked if he can read them
              again and shouted, "I love reading!" I am speechless. This is just
              amazing to watch...and listen to!
            </Typography>
            <Box className={classes.rating}>
              <img
                src={stars}
                className={classes.stars}
                alt="Five star rating"
                align="left"
              />
              <Typography align="left">Sara D.</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    );

    const stripeBadge = (
      <Box className={classes.stripeBadgeContainer}>
        <Box className={classes.stripeBadgeInnerContainer}>
          <img src={padlock} alt="Padlock icon" />
          <Typography className={classes.safeAndSecureText} align="left">
            Guaranteed safe & secure {this.state.isTablet && 'checkout'}
          </Typography>
        </Box>
        <img
          src={poweredByStripe}
          alt="Powered by Stripe"
          className={classes.stripeBadge}
        />
      </Box>
    );

    const contactUs = (
      <Typography className={classes.contactUs}>
        Text us right now with questions at{' '}
        <span>
          <a href={'sms://+14152148119?body=Hi'}>(415) 214-8119</a>
        </span>{' '}
        or email <a href="mailto: support@ello.com">support@ello.com</a>
      </Typography>
    );

    const shipping = (
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography className={classes.pricingLabel} align="left">
            Shipping
          </Typography>
        </Box>
        <Grid>
          <Typography className={classes.pricing} align="right">
            ${this.shipping}
          </Typography>
        </Grid>
      </Box>
    );

    const tax = (
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography className={classes.pricingLabel} align="left">
            Estimated Tax
          </Typography>
        </Box>
        <Grid>
          <Typography className={classes.pricing} align="right">
            ${this.state.tax}
          </Typography>
        </Grid>
      </Box>
    );

    const coupon = (
      <>
        {/* Note that we use this instead of the withQueryParams wrapper below, because that
            doesn't pass through the ref correctly. */}
        <QueryParams config={queryParams}>
          {({query, setQuery}) => {
            return (
              <Collapse in={this.state.couponVisible}>
                <Box component="div" className={classes.couponContainer}>
                  <Collapse in={!this.state.couponTitle}>
                    <TextField
                      variant="outlined"
                      className={classes.couponField}
                      label={
                        this.state.couponError
                          ? this.state.couponError
                          : 'Redeem Code'
                      }
                      value={this.state.coupon || query.coupon || ''}
                      fullWidth
                      error={!!this.state.couponError}
                      inputRef={this.couponField}
                      onChange={({target: {value}}) => {
                        setQuery({coupon: value});
                        this.setState({coupon: value});
                        this.handleCoupon(value, setQuery);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {this.state.validatingCoupon ? (
                              <CircularProgress
                                size="small"
                                className={classes.couponProgress}
                              />
                            ) : (
                              <>
                                {this.state.couponError ? (
                                  <IconButton
                                    className={
                                      classes.couponTextFieldIconButton
                                    }
                                    onClick={() => {
                                      this.handleCoupon('', setQuery);
                                      this.couponField.current.value = '';
                                      this.setState({coupon: ''});
                                    }}
                                    size="large">
                                    <HighlightOff />
                                  </IconButton>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      this.handleCoupon(
                                        this.couponField.current.value,
                                        setQuery,
                                      )
                                    }
                                    color="primary">
                                    Apply
                                  </Button>
                                )}
                              </>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Collapse>

                  <Collapse in={!!this.state.couponTitle}>
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex" justifyContent="center">
                        <IconButton
                          size="small"
                          onClick={() => {
                            this.handleCoupon('', setQuery);
                            this.couponField.current.value = '';
                          }}
                          aria-label="delete">
                          <HighlightOff />
                        </IconButton>
                        <Typography
                          className={classes.promoApplied}
                          display="inline"
                          align="left">
                          Promo Applied
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          className={classes.successCouponDiscount}
                          align="right">
                          -${this.state.discount}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      className={classes.successCoupon}
                      component="p"
                      variant="caption"
                      align="left"
                      gutterBottom>
                      {this.state.couponTitle}
                    </Typography>
                  </Collapse>
                </Box>
              </Collapse>
            );
          }}
        </QueryParams>

        <Collapse in={!this.state.couponVisible}>
          <Typography
            align="left"
            className={classes.redeemCode}
            component="p"
            variant="caption"
            onClick={() => {
              this.setState({couponVisible: true});

              window.analytics.track('Onboarding EnterCoupon');
            }}>
            Apply Promo
          </Typography>
        </Collapse>
      </>
    );

    const line = (
      <Grid container>
        <Grid xs={12}>
          <hr className={classes.lineBreak} />
        </Grid>
      </Grid>
    );

    const paymentButton = (
      <Button
        className={classes.paymentButton}
        variant="contained"
        color="primary"
        onClick={this.performAction}
        endIcon={<Icon>lock</Icon>}
        disabled={!this.state.paymentInputComplete || this.state.couponError}
        disableElevation>
        Confirm Payment
      </Button>
    );

    const termsAndPrivacyPolicy = (
      <Typography
        align="center"
        className={classes.terms}
        component="p"
        variant="caption">
        By clicking pay, you agree to our{' '}
        <a
          href="https://www.helloello.com/terms"
          target="_blank"
          rel="noopener noreferrer">
          Terms
        </a>{' '}
        and{' '}
        <a
          href="https://www.helloello.com/privacy"
          target="_blank"
          rel="noopener noreferrer">
          Privacy Policy
        </a>
        .
      </Typography>
    );

    return (
      <>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <CircularProgress color="primary" />
        </Backdrop>

        {this.state.isDesktop ? (
          <Grid container className={classes.container}>
            <Grid xs={12} md={6} className={classes.sectionLeft}>
              {stripeProviderSection}
              {testimonial}
              {stripeBadge}
              {contactUs}
            </Grid>

            <Grid xs={12} md={6} className={classes.sectionRight}>
              <Box className={classes.rightColumnInnerContainer}>
                <Box className={classes.subscriptionContainer}>
                  <ElloAppSubscription
                    titleString={this.titleString}
                    priceString={this.priceString}
                    features={this.features}
                    trialPeriodDays={this.trialPeriodDays}
                    chargeableTrialAmount={this.chargeableTrialAmount}
                    classes={classes}
                  />
                  {this.state.showShipping && shipping}
                  {this.state.tax !== 0 && tax}
                  {supportsDigital(this.productType) &&
                    !!this.trialPeriodDays && (
                      <TrialCompliance
                        classes={classes}
                        priceString={this.priceString}
                        trialPeriodDays={this.trialPeriodDays}
                      />
                    )}
                  {this.state.disableCoupon ? <br /> : coupon}
                  {line}
                  <Total
                    classes={classes}
                    trialPeriodDays={this.trialPeriodDays}
                    total={this.state.total}
                    chargeableTrialAmount={this.chargeableTrialAmount}
                  />
                  <Box className={classes.moneyBackBox}>
                    <MoneyBackBadge />
                  </Box>
                </Box>
                <Box>
                  {paymentButton}
                  {termsAndPrivacyPolicy}
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            className={this.state.isTablet ? classes.container : ''}>
            <Grid xs={12}>
              <ElloAppSubscription
                titleString={this.titleString}
                priceString={this.priceString}
                features={this.features}
                trialPeriodDays={this.trialPeriodDays}
                chargeableTrialAmount={this.chargeableTrialAmount}
                classes={classes}
              />
              {this.state.showShipping && shipping}
              {this.state.tax !== 0 && tax}
              {supportsDigital(this.productType) && !!this.trialPeriodDays && (
                <TrialCompliance
                  classes={classes}
                  priceString={this.priceString}
                  trialPeriodDays={this.trialPeriodDays}
                />
              )}
              {this.state.disableCoupon ? <br /> : coupon}
              {line}
              <Total
                classes={classes}
                trialPeriodDays={this.trialPeriodDays}
                total={this.state.total}
                chargeableTrialAmount={this.chargeableTrialAmount}
              />
              <Box className={classes.moneyBackBox}>
                <MoneyBackBadge />
              </Box>
              {stripeProviderSection}
              {paymentButton}
              {termsAndPrivacyPolicy}
              {stripeBadge}
              {testimonial}
              {contactUs}
            </Grid>
          </Grid>
        )}

        <Snackbar
          open={this.state.errorVisible}
          onClose={this.handleErrorClose}>
          <Alert onClose={this.handleErrorClose} severity="error">
            {this.state.error}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default withStyles(styles)(withApollo(PaymentPanel, {withRef: true}));
