import React, {useEffect, useState} from 'react';
import Title from './AdjustReadingLevel.request-adjustment.title';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Button,
  Typography,
} from '@mui/material';
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import {AdjustmentRequest, LevelSentence} from './AdjustReadingLevel.types';
import {levelSentences} from './AdjustReadingLevel.levels';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from '../ProfilesScreen.styles';
import AdjustReadingLevelService from './AdjustReadingLevel.service';

interface Props extends WithStyles<typeof styles> {
  currentProfileReadingLevel?: string;
  profileName?: string;
  selectedDifficulty?: AdjustmentRequest;
  newReadingLevel?: string;
  setNewReadingLevel: (newLevel: string) => void;
}

const RequestAdjustmentLevelSelectionScreen = ({
  classes,
  currentProfileReadingLevel,
  newReadingLevel,
  profileName,
  selectedDifficulty,
  setNewReadingLevel,
}: Props) => {
  const [currentSentences, setCurrentSentences] = useState<LevelSentence[]>([]);
  const [selectedHighestLevel, setSelectedHighestLevel] =
    useState<LevelSentence>();
  const [selectedLowestLevel, setSelectedLowestLevel] =
    useState<LevelSentence>();

  useEffect(() => {
    if (selectedDifficulty === AdjustmentRequest.DecreaseDifficulty) {
      const before = AdjustReadingLevelService.getSentencesBeforeLevel(
        currentProfileReadingLevel as string,
        levelSentences,
      );
      setCurrentSentences(before);
      setSelectedLowestLevel(before[0]);
      setSelectedHighestLevel(before[before.length - 1]);
    } else {
      const after = AdjustReadingLevelService.getSentencesAfterLevel(
        currentProfileReadingLevel as string,
        levelSentences,
      );
      setCurrentSentences(after);
      setSelectedHighestLevel(after[after.length - 1]);
      setSelectedLowestLevel(after[0]);
    }
  }, [currentProfileReadingLevel, selectedDifficulty]);

  const loadEasierSentences = (
    curentLowestLevel: LevelSentence | undefined,
  ) => {
    if (!curentLowestLevel) {
      return;
    }
    const getBefore = AdjustReadingLevelService.getSentencesBeforeLevel(
      curentLowestLevel.level,
      levelSentences,
    );
    if (getBefore.length) {
      setCurrentSentences(getBefore);
      setSelectedLowestLevel(getBefore[0]);
      setSelectedHighestLevel(getBefore[getBefore.length - 1]);
    }
  };

  const loadHarderSentences = (
    selectedHighestLevel: LevelSentence | undefined,
  ) => {
    if (!selectedHighestLevel) {
      return;
    }
    const getAfter = AdjustReadingLevelService.getSentencesAfterLevel(
      selectedHighestLevel.level,
      levelSentences,
    );
    if (getAfter) {
      setCurrentSentences(getAfter);
      setSelectedHighestLevel(getAfter[getAfter.length - 1]);
      setSelectedLowestLevel(getAfter[0]);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewReadingLevel(event.target.value);
  };

  return (
    <>
      <Title text="Choose a sentence that matches your child's reading ability" />
      {currentSentences.length > 0 && (
        <Typography
          variant="body1"
          mt={3}
          sx={{fontWeight: 400, fontSize: '1rem', lineHeight: '1.5rem'}}>
          Not sure? Ask your child to read these passages.
        </Typography>
      )}

      <RadioGroup onChange={handleChange}>
        {currentSentences.length === 0 ? (
          <Typography mt={3}>
            {currentProfileReadingLevel === 'A1' && (
              <>
                [{profileName}] is currently at the first level available at
                Ello. Ello is deisgned for children who can read sentences such
                as ‘I am a cat’ and beyond.
              </>
            )}
            {currentProfileReadingLevel === 'P3' && (
              <>
                [{profileName}] is currently at the most advanced level
                available at Ello. We are unable to increase his level at this
                time.
              </>
            )}
          </Typography>
        ) : (
          currentSentences.map(sentence => (
            <FormControlLabel
              key={sentence.index}
              value={sentence.level}
              control={<Radio sx={{display: 'none'}} />}
              label={sentence.sentence}
              className={`${classes.adjustmentRadioButton} ${
                newReadingLevel === sentence.level
                  ? classes.adjustmentRadioButtonSelected
                  : ''
              }`}
            />
          ))
        )}
      </RadioGroup>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 3,
        }}>
        <Button
          startIcon={<ArrowBack />}
          className={classes.levelSelectionLink}
          onClick={event => {
            event.preventDefault();
            loadEasierSentences(selectedLowestLevel);
          }}>
          Easier text
        </Button>
        <Button
          endIcon={<ArrowForward />}
          className={classes.levelSelectionLink}
          onClick={event => {
            event.preventDefault();
            loadHarderSentences(selectedHighestLevel);
          }}>
          Harder text
        </Button>
      </Box>
    </>
  );
};

export default withStyles(styles)(RequestAdjustmentLevelSelectionScreen);
