import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import useStyles from './BookCard.styles';

type props = {
  book?: {id?: string; title?: string; author?: string; coverPhotoURL?: string};
  onClick?: () => void;
};

const BookCard: FC<props> = ({book, onClick}: props) => {
  const classes: any = useStyles({coverPhotoURL: book?.coverPhotoURL});

  return (
    <Box className={classes.bookCard} onClick={onClick}>
      <Box className={classes.imgContainer}></Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.title}>{book?.title}</Typography>
        <Typography className={classes.author}>By {book?.author}</Typography>
      </Box>
    </Box>
  );
};

export default BookCard;
