type OnboardingPanelProps = {
  redirectPath?: string;
  androidRedirectPath?: string;
};

function redirectPath(props: OnboardingPanelProps, tablet: string) {
  const isAndroidUser = hasSelectedAndroidDevice(tablet);
  if (isAndroidUser && props?.androidRedirectPath) {
    return props.androidRedirectPath;
  }

  return props?.redirectPath;
}

function hasSelectedAndroidDevice(tablet: string) {
  if (!tablet) {
    return false;
  }

  const androidTablets = [
    'android tablet',
    'amazon fire tablet',
    'samsung galaxy tablet',
  ];
  return androidTablets.includes(tablet.toLowerCase());
}

export const OnboardingPanelService = {
  redirectPath,
  hasSelectedAndroidDevice,
};
