import {useMutation} from '@apollo/client';
import {Dialog} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {FlowBanner} from 'customerPortal/components/Flow';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React, {useEffect, useRef, useState} from 'react';
import {ReactComponent as IconCross} from 'shared/assets/icons/iconCross.svg';
import Button, {ButtonType} from 'shared/components/common/Button';
import * as palette from 'shared/styles/palette';
import {toUSDateFormat} from '../../../shared/utils/dateUtils';
import {supportsDigital} from '../../../shared/utils/productType';
import CancellationConfirmation from './CancellationConfirmation/CancellationConfirmation';
import CancellationConfirmed from './CancellationConfirmed/CancellationConfirmed';
import CancellationFeedback from './CancellationFeedback/CancellationFeedback';
import {CANCEL_SUBSCRIPTION_MUTATION} from './CancellationForm.query';
import {cancelSubscriptionWithMutation} from './CancellationForm.service';
import styles from './CancellationForm.styles';
import CancellationReason from './CancellationReason/CancellationReason';
import {
  digitalCancellationReasons,
  physicalCancellationReasons,
} from './constants';
import {CancellationFormState} from './types';

const other = 'Other';

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  subscriptionPeriodEnd?: number;
  productType: string | undefined;
  subscriptionStatus: string;
  profileNames: string[];
  onClose: () => void;
}

const CancellationForm = ({
  classes,
  open,
  subscriptionPeriodEnd,
  productType,
  subscriptionStatus,
  profileNames,
  onClose,
}: Props) => {
  const isMobile = useMobileLayout();

  const initState = CancellationFormState.SelectReasonCategory;

  const [state, setState] = useState<CancellationFormState>(initState);
  const stateHistory = useRef<CancellationFormState[]>([]);
  const [cancelCategory, setCancelCategory] = useState('');
  const [cancelDetail, setCancelDetail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cancelSubscriptionMutation] = useMutation(
    CANCEL_SUBSCRIPTION_MUTATION,
  );
  const cancel = cancelSubscriptionWithMutation(cancelSubscriptionMutation);
  const subscriptionEndDate =
    subscriptionPeriodEnd && toUSDateFormat(subscriptionPeriodEnd);
  const cancellationReasonChoices = supportsDigital(productType)
    ? digitalCancellationReasons
    : physicalCancellationReasons;

  useEffect(() => {
    // On open, reset the form state
    if (open) {
      setState(initState);
      stateHistory.current = [];
      setCancelCategory('');
      setCancelDetail('');
    }
  }, [open, initState]);

  const transition = (nextState: CancellationFormState) => {
    stateHistory.current.push(state);
    setState(nextState);
  };

  const goBack = () => {
    const prevState = stateHistory.current.pop();
    if (!!prevState) {
      setState(prevState);
    } else {
      onClose();
    }
  };

  const onSkip = () => {
    switch (state) {
      case CancellationFormState.SelectReasonCategory:
      case CancellationFormState.SelectReasonDetail:
        transition(CancellationFormState.FeedbackForm);
        break;
      case CancellationFormState.FeedbackForm:
        transition(CancellationFormState.CancellationConfirmation);
        break;
      default:
        console.error(`Cannot perform skip from ${state}`);
        break;
    }
  };

  const cancelSubscription = async () => {
    const category = cancellationReasonChoices[cancelCategory]?.label || other;
    const detail =
      cancellationReasonChoices[cancelCategory]?.subchoices[cancelDetail]
        ?.label || other;

    const cancellationDetails = {
      cancelCategory: category,
      cancelDetail: detail,
      feedback,
    };

    setIsLoading(true);
    await cancel(cancellationDetails);

    setIsLoading(false);
    transition(CancellationFormState.CancellationConfirmed);
  };

  const userSelectedOther = (resp: any) => {
    return ['other', 'dont-want-to-pay'].includes(resp);
  };

  const onNext = (resp: any = '') => {
    switch (state) {
      case CancellationFormState.SelectReasonCategory:
        if (userSelectedOther(resp)) {
          transition(CancellationFormState.FeedbackForm);
          break;
        }
        transition(CancellationFormState.SelectReasonDetail);
        break;
      case CancellationFormState.SelectReasonDetail:
        // if (resp === 'no-use') {
        //   transition(CancellationFormState.CancellationOffer);
        // } else {
        transition(CancellationFormState.FeedbackForm);
        // }
        break;
      case CancellationFormState.FeedbackForm:
        transition(CancellationFormState.CancellationConfirmation);
        break;
      case CancellationFormState.CancellationConfirmation:
        cancelSubscription();
        break;
      case CancellationFormState.CancellationConfirmed:
        window.location.reload();
        break;
      default:
        console.error(`Cannot find next state for ${state}`);
        break;
    }
  };

  const onTapOverlay = () => {
    if (state !== CancellationFormState.CancellationConfirmed) {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {borderRadius: isMobile ? 0 : 16},
        }}
        BackdropProps={{
          style: {backgroundColor: palette.blue500, opacity: 0.4},
        }}
        fullScreen={isMobile}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={onTapOverlay}>
        <div className={classes.container}>
          {isMobile ? (
            <>
              <FlowBanner
                onBack={goBack}
                hideBackBtn={
                  state === CancellationFormState.CancellationConfirmed
                }
              />
            </>
          ) : (
            <>
              {state !== CancellationFormState.CancellationConfirmed && (
                <Button
                  variant={ButtonType.Icon}
                  onClick={onClose}
                  aria-label="Close"
                  className={classes.closeButton}>
                  <IconCross />
                </Button>
              )}
            </>
          )}
          {isLoading ? (
            <CircularProgress
              style={{
                margin: isMobile ? '40vh auto' : '200px auto',
                display: 'block',
              }}
            />
          ) : (
            <>
              {state === CancellationFormState.SelectReasonCategory && (
                <CancellationReason
                  choices={cancellationReasonChoices}
                  subscriptionStatus={subscriptionStatus}
                  onConfirm={option => {
                    setCancelCategory(option);
                    onNext(option);
                  }}
                  onSkip={onSkip}
                  hideDesktopBackButton
                />
              )}
              {state === CancellationFormState.SelectReasonDetail && (
                <CancellationReason
                  choices={cancellationReasonChoices[cancelCategory].subchoices}
                  subscriptionStatus={subscriptionStatus}
                  onConfirm={option => {
                    setCancelDetail(option);
                    onNext(option);
                  }}
                  onSkip={onSkip}
                  onBack={goBack}
                  titleText={'Help Ello improve by specifying the problem'}
                />
              )}
              {state === CancellationFormState.FeedbackForm && (
                <CancellationFeedback
                  onConfirm={input => {
                    setFeedback(input);
                    onNext();
                  }}
                  onSkip={onSkip}
                  onBack={goBack}
                />
              )}
              {state === CancellationFormState.CancellationConfirmation && (
                <CancellationConfirmation
                  onConfirm={onNext}
                  onBack={onClose}
                  endDate={subscriptionEndDate?.toString()}
                  subscriptionStatus={subscriptionStatus}
                  profileNames={profileNames}
                />
              )}
              {state === CancellationFormState.CancellationConfirmed && (
                <CancellationConfirmed
                  onConfirm={onNext}
                  onBack={goBack}
                  hideDesktopBackButton
                />
              )}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(CancellationForm);
