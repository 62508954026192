import {isDateInPast, toFriendlyDate} from '../../../../shared/utils/dateUtils';
import {isActiveSubscription} from '../../../../shared/utils/subscriptionStatus';

export function subscriptionStatusText(
  subscriptionStatus: string | undefined,
  subscriptionEndDate: number | undefined,
) {
  if (!subscriptionEndDate) {
    return;
  }

  const subscriptionEndDateFormatted =
    subscriptionEndDate && toFriendlyDate(subscriptionEndDate, false);

  if (isActiveSubscription(subscriptionStatus)) {
    return `Renews on ${subscriptionEndDateFormatted}`;
  }

  if (isDateInPast(subscriptionEndDate)) {
    return `Inactive`;
  }

  return `Expires on ${subscriptionEndDateFormatted}`;
}

export function isInactiveSubscription(
  subscriptionEndDate: number | undefined,
) {
  if (!subscriptionEndDate) {
    return;
  }

  return isDateInPast(subscriptionEndDate);
}

export function toTitleCase(str: string): string {
  return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
}
