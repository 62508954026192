import * as Sentry from '@sentry/react';

export function initSentry() {
  Sentry.init({
    attachStacktrace: true,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.captureConsoleIntegration({levels: ['error']})],
    sampleRate: 0.1,
  });
}
