import React, {useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import {ReactComponent as MegaPhone} from '../../../shared/assets/mega-phone.svg';
import styles from './ReferralLink.styles';
import Button from '../../../shared/components/common/Button';
import {ReactComponent as ShareIcon} from '../../../shared/assets/share.svg';
import Link from '@mui/material/Link';
import {ReferralLinkService} from './ReferralLink.service';
import {TextField, InputAdornment} from '@mui/material';
import CopiedText from '../../../shared/components/ShareableLink/ShareableLink.link.copied-text';
import CopyButton from '../../../shared/components/common/CopyComponent/CopySheet.copy-button';

interface Props extends WithStyles<typeof styles> {
  referralCode: string;
}

const ReferralLink = ({classes, referralCode}: Props) => {
  const [copied, setCopied] = useState(false);

  const inputRef = useRef(null);
  const handleDoubleClick = () => {
    if (inputRef.current) {
      const input = inputRef.current as any;
      input.select();
    }
  };

  const copyToClipboard = async () => {
    const hasCopiedLink = await ReferralLinkService.copyLinkToClipboard(
      referralCode,
    );
    setCopied(hasCopiedLink);
    setTimeout(() => setCopied(false), 3000);
  };

  const onShareClick = async () => {
    await ReferralLinkService.copyLinkAndShare(referralCode);
  };

  const canOpenShareSheet = ReferralLinkService.canShareLink();
  const link = ReferralLinkService.referralLink(referralCode);
  const displayLink = link.replace('https://', '').replace('http://', '');

  return (
    <Box className={classes.container}>
      <MegaPhone />
      {canOpenShareSheet && (
        <Button className={classes.shareSheet} onClick={onShareClick}>
          <ShareIcon className={classes.shareIcon} />
          Share Ello
        </Button>
      )}

      <Typography className={classes.header}>Share Ello!</Typography>

      <TextField
        value={displayLink}
        inputRef={inputRef}
        className={classes.copySheet}
        onDoubleClick={handleDoubleClick}
        InputProps={{
          className: classes.copySheetInput,
          endAdornment: (
            <InputAdornment position="end">
              {copied ? (
                <CopiedText />
              ) : (
                <CopyButton copyToClipboard={copyToClipboard} />
              )}
            </InputAdornment>
          ),
        }}
      />

      <Typography className={classes.shareText}>
        Share with friends and family and you both get one month free!{' '}
        <Link className={classes.link}>Learn more</Link>
      </Typography>
    </Box>
  );
};

export default withStyles(styles)(ReferralLink);
