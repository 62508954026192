import {useQuery, useLazyQuery} from '@apollo/client';
import {CardActionArea, Chip, Typography} from '@mui/material';
import clsx from 'clsx';
import CancellationForm from 'customerPortal/components/CancellationForm/CancellationForm';
import Card from 'customerPortal/components/Card/Card';
import Section from 'customerPortal/components/Section';
import {subscriptionIcon} from 'customerPortal/utils/bundleIcons';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as IconAddChild} from 'shared/assets/icons/iconAddChild.svg';
import {ReactComponent as IconBilling} from 'shared/assets/icons/iconBilling.svg';
import {ReactComponent as IconCancel} from 'shared/assets/icons/iconCancel.svg';
import {ReactComponent as IconChevron} from 'shared/assets/icons/iconChevron.svg';
import {ReactComponent as RenewSubscriptionIcon} from 'shared/assets/icons/renewSubscription.svg';
import Alert from 'shared/components/common/Alert/Alert';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import {CustomerSubscription} from 'shared/types/customer';
import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';
import {
  isDigitalSubscription,
  isUpgradableCustomer,
  supportsDigital,
} from '../../../../shared/utils/productType';
import {
  isCanceledSubscription,
  isCancellationStatus,
} from '../../../../shared/utils/subscriptionStatus';
import {QUERY_ACCOUNT_DETAILS} from '../AccountDetailsScreen/AccountDetails.query';
import {
  QUERY_CURRENT_SUBSCRIPTION,
  QUERY_STRIPE_PORTAL,
} from './AccountSubscription.query';
import SectionHeader from './AccountSubscription.section-header';
import {
  cardActionStyles,
  subscriptionInfoStyles,
} from './AccountSubscription.styles';
import {Customer} from './AccountSubscription.types';
import {
  toTitleCase,
  isInactiveSubscription,
} from './AcountSubscription.service';
import {sendSlackMessage, SlackMessageTypes} from 'shared/utils/slack';

type QueryResult = Pick<CustomerSubscription, 'title' | 'description' | 'name'>;
type subscriptionInfoType = QueryResult & {
  isInactive?: boolean;
  productType: string | undefined;
};

const appleBillingManagementUrl =
  'https://apps.apple.com/account/subscriptions';

const SubscriptionInfo = ({
  title,
  description,
  name,
  isInactive,
  productType,
}: subscriptionInfoType) => {
  const classes = subscriptionInfoStyles();
  const formatedTitle = toTitleCase(title);
  return (
    <span className={classes.content}>
      <img
        src={subscriptionIcon(productType, name)}
        alt=""
        className={clsx(classes.icon, isInactive && classes.inactiveIcon)}
      />
      <span className={classes.desc}>
        <Typography component="h1" variant="h5" className={classes.name}>
          {formatedTitle}
        </Typography>
        <Typography component="p" variant="h6" className={classes.planSubtitle}>
          {description}
        </Typography>
      </span>
    </span>
  );
};

interface ActionCardProps {
  route?: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string | React.ReactNode;
  alternativeSubtitle?: string;
  tag?: string;
  onClick?: () => void;
}

const ActionCard = ({
  route,
  Icon,
  title,
  subtitle,
  alternativeSubtitle,
  tag,
  onClick = () => {},
}: ActionCardProps) => {
  const classes = cardActionStyles();
  const isMobile = useMobileLayout();

  const linkProps = !route ? {} : {component: Link, to: route};

  return (
    <CardActionArea
      className={classes.container}
      {...linkProps}
      onClick={onClick}>
      <Card className={classes.card}>
        <div className={classes.cardContent}>
          <Icon className={classes.cardIcon} />
          <div>
            <span className={classes.titleContainer}>
              <Typography component="h1" variant="h6" className={classes.title}>
                {title}
              </Typography>
              {tag && (
                <Chip size="small" label={tag} className={classes.chip} />
              )}
            </span>
            {isMobile ? (
              <>
                {alternativeSubtitle && (
                  <Typography className={classes.altSubtitle}>
                    {alternativeSubtitle}
                  </Typography>
                )}
              </>
            ) : (
              <Typography className={classes.actionSubtitle}>
                {subtitle}
              </Typography>
            )}
          </div>
        </div>
        <IconChevron className={classes.arrow} />
      </Card>
    </CardActionArea>
  );
};

const AccountSubscription = () => {
  const [showCancellationForm, setShowCancellationForm] = useState(false);

  const onError = (error: any) =>
    console.error('Account Subscription::', error);

  const {data, loading, error} = useQuery<{subscription: QueryResult}>(
    QUERY_CURRENT_SUBSCRIPTION,
    {
      onError,
    },
  );

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useQuery<{
    customer: Customer;
  }>(QUERY_ACCOUNT_DETAILS, {
    onError,
  });

  const profileNames =
    customerData?.customer.profiles.map(profile => profile.name) || [];

  const billingSource = customerData?.customer?.billingSource || 'Stripe';
  const isStripeBillingSource = billingSource === 'Stripe';
  const billingPortal = billingSource === 'RevenueCat' ? 'Apple' : 'Stripe';

  const closeCancellationForm = () => {
    setShowCancellationForm(false);
  };

  const openCancellationForm = () => {
    window.analytics?.track('Cancellation Started');
    setShowCancellationForm(true);
  };

  const renewSubcription = () => {
    if (isStripeBillingSource) {
      const message = `${customerData?.customer.userDetails.email} would like to resubscribe. Please reach out to them.`;
      sendSlackMessage(SlackMessageTypes.RenewSubscription, message);
      return;
    }
    window.location.assign(appleBillingManagementUrl);
  };

  const [
    queryStripePortal,
    {error: stripePortalError, loading: isStripePortalLoading},
  ] = useLazyQuery(QUERY_STRIPE_PORTAL, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    variables: {
      returnUrl: window.location.href,
    },
    onCompleted: data => {
      if (data) {
        window.location.assign(data.createStripePortalSession.url);
      }
    },
    onError: error => console.error('Stripe portal::', error),
  });

  const hasError = error || customerError;
  const isLoading = loading || customerLoading || isStripePortalLoading;
  const subscriptionStatus = customerData?.customer.subscriptionStatus;
  const subscriptionPeriodEnd = customerData?.customer.subscriptionPeriodEnd;
  const customerProductType = customerData?.customer.productType;
  const hasInactiveSubscription = isInactiveSubscription(subscriptionPeriodEnd);

  const showCancellationButton =
    isStripeBillingSource &&
    isDigitalSubscription(customerProductType) &&
    !isCanceledSubscription(subscriptionStatus) &&
    featureFlags.isOn(FEATURE_IDS.CANCELLATION_MODAL);

  const showRenewalButton =
    supportsDigital(customerProductType) &&
    isCancellationStatus(subscriptionStatus) &&
    featureFlags.isOn(FEATURE_IDS.RENEW_SUBSCRIPTION);

  const showManageBilling = featureFlags.isOn(FEATURE_IDS.SHOW_MANAGE_BILLING);

  return (
    <>
      <Section
        titleSection={
          <SectionHeader
            subscriptionStatus={subscriptionStatus}
            subscriptionPeriodEnd={subscriptionPeriodEnd}
          />
        }
        error={hasError ? 'Unable to load subscription' : ''}
        loading={isLoading}>
        <>
          {data?.subscription && (
            <>
              <SubscriptionInfo
                title={data.subscription.title}
                description={data.subscription.description}
                name={data.subscription.name}
                isInactive={!!hasInactiveSubscription}
                productType={customerProductType}
              />
              {showManageBilling && (
                <ActionCard
                  onClick={() => {
                    if (billingSource === 'Stripe') {
                      queryStripePortal();
                      return;
                    }
                    window.location.assign(appleBillingManagementUrl);
                  }}
                  Icon={IconBilling}
                  title={'Manage Payment & Billing'}
                  subtitle={
                    'View or make changes to your payment method, billing' +
                    `information or subscription on ${billingPortal}`
                  }
                />
              )}
              {isUpgradableCustomer(customerProductType) && (
                <ActionCard
                  route={PRIVATE_ROUTES.ADD_CHILD.path}
                  Icon={IconAddChild}
                  title={'Add Child'}
                  subtitle={
                    <>
                      Add a child to share books with or to sign up for an
                      additional book bundle at a different reading level.
                    </>
                  }
                />
              )}
              {showCancellationButton && (
                <ActionCard
                  Icon={IconCancel}
                  title={'Cancel Subscription'}
                  onClick={() => openCancellationForm()}
                  subtitle={
                    <>
                      Cancel your current Ello subscription. Your subscription
                      will remain active until the end of your current period.
                    </>
                  }
                />
              )}

              {showRenewalButton && (
                <ActionCard
                  Icon={RenewSubscriptionIcon}
                  title={'Renew Subscription'}
                  onClick={renewSubcription}
                  subtitle={
                    <>
                      Your child’s level and reading progress will be restored,
                      they’ll continue where you left off
                    </>
                  }
                />
              )}
            </>
          )}
        </>
      </Section>
      {stripePortalError && <Alert error={'Unable to connect to Stripe'} />}
      {showCancellationForm &&
        featureFlags.isOn(FEATURE_IDS.CANCELLATION_MODAL) && (
          <CancellationForm
            productType={customerProductType}
            subscriptionPeriodEnd={customerData?.customer.subscriptionPeriodEnd}
            open={showCancellationForm}
            onClose={closeCancellationForm}
            profileNames={profileNames}
            subscriptionStatus={subscriptionStatus || ''}
          />
        )}
    </>
  );
};

export default AccountSubscription;
