import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material/styles';
import {
  BORDER_RADIUS,
  LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
} from 'shared/styles/breakpoints';
import {
  gray,
  gray700,
  gray900,
  orange500,
  teal500,
  white,
} from 'shared/styles/palette';

export const subscriptionInfoStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.down(LAPTOP_BREAKPOINT)]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  name: {
    fontWeight: 700,
    fontSize: '1.625rem',
    color: gray900,
    margin: `${theme.spacing(2)} 0`,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '1.375rem',
    },
  },
  desc: {
    flex: 1,
  },
  planSubtitle: {
    color: gray700,
    fontWeight: 400,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontWeight: 500,
      fontSize: `1rem`,
    },
  },
  icon: {
    maxWidth: '200px',
    marginRight: theme.spacing(6),
    [theme.breakpoints.down(LAPTOP_BREAKPOINT)]: {
      margin: '0',
    },
  },
  inactiveIcon: {
    filter: 'grayscale(100%)',
  },
}));

export const cardActionStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: BORDER_RADIUS,
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: gray,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginTop: theme.spacing(3),
    },
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  cardIcon: {
    minWidth: `50px`,
    alignSelf: 'baseline',
    marginRight: theme.spacing(1),
  },
  arrow: {
    minWidth: `30px`,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  chip: {
    backgroundColor: orange500,
    color: white,
    fontWeight: 700,
    fontSize: `0.75rem`,
    padding: `0 ${theme.spacing(0.5)}`,
  },
  title: {
    fontWeight: 700,
    color: gray900,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '1rem',
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
  },
  actionSubtitle: {
    fontWeight: 400,
    fontSize: '0.875rem',
    color: gray700,
  },
  altSubtitle: {
    display: 'inline-block',
    fontWeight: 500,
    fontSize: `0.812rem`,
    color: gray700,
  },
}));

export const accountSubscriptionStyles = makeStyles((theme: Theme) => ({
  subscriptionChip: {
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      marginLeft: `${theme.spacing(2)} `,
    },
    fontWeight: 700,
    fontSize: `0.75rem`,
  },
  activeSubscriptionChip: {
    color: teal500,
    borderColor: teal500,
  },
  inactiveSubscriptionChip: {
    color: orange500,
    borderColor: orange500,
  },
}));
