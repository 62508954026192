import {Container, Typography} from '@mui/material';
import {FlowLayout} from 'customerPortal/components/Flow';
import React from 'react';
import bundleCustom from 'shared/assets/bundles/bundleCustom.svg';
import useStyles from './CustomPlanError.styles';

interface Props {
  message?: string;
}

const CustomPlanError = ({message}: Props) => {
  const classes = useStyles();
  return (
    <FlowLayout title={`Let's add a new reader`}>
      <img src={bundleCustom} alt="" />
      <Container maxWidth="md">
        <Typography component="p" className={classes.text}>
          {message}
          {!!message && <br />}
          Please get in touch at{' '}
          <a href={'sms://+14152148119'}>(415) 214-8119</a> or email{' '}
          <a href="mailto:support@helloello.com">support@helloello.com</a> and
          we'll work out the best option for your young readers.
        </Typography>
      </Container>
    </FlowLayout>
  );
};

export default CustomPlanError;
