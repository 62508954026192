/*
 * Onboarding Panel. This can be packaged into a separate module for use within Webflow.
 *
 * The onboarding panel renders a stepper of pages. Each page can belong to one step
 * on the stepper. A step can nest multiple pages. Finally, each page can have multiple
 * questions, which are rendered as react components.
 *
 * {
 *   stepTitles: ['Redeem Code', 'Confirmation'],
 *   steps: [
 *     {
 *       step: 0,
 *       questions: [
 *         {
 *           title: 'Enter your Code',
 *           type: 'text',
 *           label: 'Coupon Code',
 *           field: 'coupon',
 *         },
 *         {
 *           title: "What's your phone number?",
 *           subtitle:
 *             "We'll use this number to get in touch with you.",
 *           type: 'phone',
 *           field: 'phone',
 *         },
 *       ],
 *     },
 *     {
 *       step: 1,
 *       questions: [{type: 'confirmation'}],
 *     },
 *   ],
 * }
 *
 * Flows usually end in a single-question panel of type 'payment'.
 *
 * Here's how it fits together.
 * - Each question component gets passed an onValidationChanged() handler and an onNext() handler.
 * - Any question calls the onNext() handler when it would like to advance to the next page. This triggers
 *   handleNext() on this class.
 * - Any question calls the onValidationChanged() handler when its validation status changes. Either the
 *   question has all of its required input and the input is valid, or it requires more input before we
 *   can advance to the next page.
 * - handleNext() only advances to the next page if the validators of all questions are happy.
 */

import {withApollo} from '@apollo/client/react/hoc';
import {Alert, Backdrop, CircularProgress, Snackbar} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import {withRouter} from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import BorderLinearProgress from 'shared/components/common/BorderLinearProgress';

import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import * as palette from 'shared/styles/palette';
import {
  DEFAULT_LOCALE,
  LocalizationContext,
  STRINGS,
} from 'shared/utils/localization';
import {collectQueryParams} from 'shared/utils/queryParams';
import {
  resolveQueryString,
  resolveQueryStrings,
} from 'shared/utils/stringUtils/stringUtils';
import {
  JsonParam,
  NumberParam,
  StringParam,
  withQueryParams,
} from 'use-query-params';
import {
  OnboardingFlowType,
  unvalidatedOnboardingFlowTypes,
} from '../../types/onboardingFlow.type';
import Image from '../Image/Image';
import {VALIDATE_ONBOARDING_DATA} from '../InAppSubmitPanel/InAppSubmitPanel.query';
import {handleEmailSubmit} from '../common/FormTextField/emailSubmit';
import OnboardingNavigator from './OnboardingNavigator';
import OnboardingBackButton from './OnboardingNavigator/OnboardingBackButton';
import QuestionTypeField from './OnboardingPanel.question.type';
import {OnboardingPanelService} from './OnboardingPanel.service';
import styles from './OnboardingPanel.styles';

/**
 * Query parameters
 * p: starting page (param gets processed into the history state)
 * val: cache of validation state. We could re-run the validation whenever we reload in the future,
 *  but that's surprisingly hard to do in a react-ful way. We'd have to convert all our functional
 *  components to class ones.
 * confirmationId: If present, renders the screen in confirmation mode
 */
const queryParams = {
  p: NumberParam,
  val: JsonParam,
  confirmationId: StringParam,
};

class OnboardingPanel extends React.Component {
  actionPanel = React.createRef();
  flowContainer = React.createRef();

  // These may be post-confirmation. This variable controls whether the final page action is "Submit"
  // or "Finish".
  hasSubmissionQuestions =
    this.props.flowSpec.steps[this.props.flowSpec.steps.length - 1].questions
      .length > 1;

  // Active starting step is always last (confirmation) step when confirmationId is present; otherwise grab of query params or start at 0.
  state = {
    activeStep: this.props.query.confirmationId
      ? this.props.flowSpec.steps.length - 1
      : this.props.query.p || 0,

    error: null,
    errorVisible: false,

    loading: false,

    // Array of arrays, such that questionValidations[i][j] is the validation status of the jth question
    // on the ith page, initialized to false (not valid) unless explicitly not required, is missing a type, or is a submit/survey type
    questionValidations:
      this.props.query.val ||
      this.props.flowSpec.steps.map((step, idx) =>
        step.questions.map(
          question =>
            question.required === false ||
            !question.type ||
            unvalidatedOnboardingFlowTypes.includes(question.type),
        ),
      ),

    completed: false,

    // Used to adjust for keyboard focus on mobile
    inputFocusOffset: null,
    isDesktop: window.matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`).matches,
  };

  mediaHandler = e => this.setState({isDesktop: e.matches});

  componentDidMount() {
    this.historyListener = this.props.history.listen((location, action) => {
      // Absolutely refuse to handle any backwards or forward button action once confirmed
      if (this.state.completed) {
        return;
      }

      const isBackAction = action === 'POP';
      const isForwardAction = action === 'PUSH';
      if (isForwardAction) {
        // Once we pushed a screen, add the page to URL for re-linking, which triggers a history REPLACE.
        this.props.setQuery({p: location.state.page});
      } else if (isBackAction) {
        // Handle browser back and forth buttons
        if (!location.state || location.state.page < this.state.activeStep) {
          this.handleBack(location.state ? location.state.page : null);
        } else if (location.state.page > this.state.activeStep) {
          this.handleNext(location.state ? location.state.page : null, false);
        }
      }
    });

    window
      .matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`)
      .addEventListener('change', this.mediaHandler);

    // Rewardful analytics
    if (window.rewardful) {
      window.rewardful('ready', () => {
        if (window.Rewardful.affiliate) {
          window.analytics.identify({
            rewardful_affiliate_token: window.Rewardful.affiliate.token,
            rewardful_affiliate_name: window.Rewardful.affiliate.name,
            rewardful_campaign_name: window.Rewardful.campaign.name,
            rewardful_campaign_id: window.Rewardful.campaign.id,
          });
        }
      });
    }

    const queryParams = collectQueryParams();
    const segmentAnonymousId = queryParams.segmentAnonymousId;
    if (segmentAnonymousId) {
      ['segmentAnonymousId'].forEach(field => delete queryParams[field]);
      window.analytics.identify(
        {
          ...queryParams,
        },
        {anonymousId: segmentAnonymousId},
      );
    }
  }

  componentWillUnmount() {
    // Unbind listener
    this.historyListener();
    window
      .matchMedia(`(min-width: ${TABLET_BREAKPOINT}px)`)
      .removeEventListener('change', this.mediaHandler);
  }

  // Make sure flow container scrolls to top
  mountFlowContainer = () => {
    if (this.flowContainer.current) this.flowContainer.current.scrollTop = 0;
  };

  handleEmailPhoneValidate = (flowSpec, activeStep) => {
    const currentStep = flowSpec?.steps?.[activeStep]?.questions || [];

    const hasQuestionType = type => currentStep.some(q => q.type === type);

    const isEmailPresent =
      hasQuestionType('emailSubmit') || hasQuestionType('email');
    const isPhonePresent = hasQuestionType('phone');

    if (!(isEmailPresent && isPhonePresent)) {
      return true;
    }

    this.setState({loading: true});

    const {email, phone, name} = collectQueryParams();

    return this.props.client
      .mutate({
        mutation: VALIDATE_ONBOARDING_DATA,
        variables: {email, phoneNumber: phone, name},
      })
      .then(() => {
        this.setState({loading: false});
        return true;
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error.message,
          errorVisible: true,
        });
        return false;
      });
  };

  /**
   * Handles next button click or onNext from a question that wants to advance.
   * @param {Number} goToStep: If provided, jumps to this step instead of going to the next one.
   * @param {Boolean} setHistory: If true, advances history for the browser forward button click.
   */
  handleNext = async (goToStep = null, setHistory = true) => {
    const isValid = await this.handleEmailPhoneValidate(
      this.props.flowSpec,
      this.state.activeStep,
    );
    if (!isValid) {
      return;
    }

    handleEmailSubmit(
      this.props.flowName,
      this.props.flowSpec,
      this.state.activeStep,
    );

    let nextStep;

    // Check for directive override
    if (goToStep !== null) nextStep = goToStep;
    else {
      // Check for conditional branching based on a single question
      const branchStep = this.getBranchStep();
      if (branchStep !== null) nextStep = branchStep;
      // If none of those, just advance to the next page
      else nextStep = this.state.activeStep + 1;
    }

    if (
      // All questions in the current step have been validated
      this.state.questionValidations[this.state.activeStep].every(
        isValid => !!isValid,
      )
    ) {
      // If payment, submit, or survey type is on screen, intercept and trigger "special" action
      if (this.actionPanel.current) {
        // If we're in post-submission mode, just finish up entirely
        if (this.hasSubmissionQuestions) {
          this.handleFinish();
        } else {
          this.actionPanel.current.getWrappedInstance().performAction();
        }
      } else if (nextStep < this.props.flowSpec.steps.length) {
        this.goToNextStep(nextStep, setHistory);
      }
    }
  };

  goToNextStep = (goToStep = null, setHistory = true) => {
    let nextStep;

    // Check for directive override
    if (goToStep !== null) nextStep = goToStep;
    else {
      // Check for conditional branching based on a single question
      const branchStep = this.getBranchStep();
      if (branchStep !== null) nextStep = branchStep;
      // If none of those, just advance to the next page
      else nextStep = this.state.activeStep + 1;
    }

    this.setState({activeStep: nextStep, inputFocusOffset: null});

    // Advance history, so we can take care of the browser back button.
    // Note that we must use this.props.history.location.search here rather than
    // this.props.location.search because the location passed in by the router may not include
    // the query params filled in by the sub-component just yet and a push with wrong query
    // params would remove them from the URL again.
    // From there, this.historyListener kicks in and attaches the "starting page param" p to
    // the query string with a history replacement.
    if (setHistory) {
      this.props.history.push({
        pathname: this.props.history.location.pathname,
        search: this.props.history.location.search,
        state: {page: nextStep},
      });
    }

    const excludedFields = [
      'val',
      'name',
      'email',
      'phone',
      'p',
      'segmentAnonymousId',
    ];

    const queryParams = collectQueryParams();

    // Remove excluded fields from queryParams
    excludedFields.forEach(field => delete queryParams[field]);

    const flowSpec = this.props.flowSpec;
    window.analytics.track('Onboarding NextStep', {
      activeStep: nextStep,
      stepTitle: flowSpec.stepTitles[flowSpec.steps[nextStep].step],
      ...queryParams,
    });

    this.mountFlowContainer();
  };

  /**
   * Moves one step further to the confirmation page. Only called by the special actionPanel components.
   */
  handleConfirm = (confirmation, params = {}) => {
    window.analytics.track('Onboarding Confirm');
    let updatedUrl;

    if (
      this.actionPanel.current &&
      this.actionPanel.current.props.redirectPath
    ) {
      const queryParams = collectQueryParams();

      const redirectPath = OnboardingPanelService.redirectPath(
        this.actionPanel.current.props,
        queryParams.tablet,
      );

      // We use onboardingResponse instead of confirmationId url query param so that when
      // we redirect to a new onboarding flow, we don't automatically navigate to last step
      const resolvedRedirectPath = resolveQueryString(
        redirectPath,
        DEFAULT_LOCALE,
      );
      updatedUrl = `${resolvedRedirectPath}${
        redirectPath.includes('?') ? '&' : '?'
      }onboardingResponseUid=${confirmation.confirmationId}`;
      // Add extra endpoint param to pass into GenericConfirmPanel if needed.
      if (params.endpoint) {
        updatedUrl = `${updatedUrl}&endpoint=${params.endpoint}`;
      }
    } else {
      // Perform a full redirect, since the onboarding confirmation page is persistent
      // We also always want it to be served on www.helloello.com/start for general customers and
      // currently point to portal.helloello.com/onboarding for the in-app onboarding only.
      const pathname = window.location.pathname.includes('/onboarding')
        ? window.location.pathname
        : '/start';

      // phone is collected to redirect native app users to chat screen after onboarding
      // email is collected for updateOnboarding mutation's zapier hook
      // confirmationId is collected for updateOnboarding mutation
      updatedUrl = `${pathname}?flow=${this.props.flowName}&phone=${params.phone}&email=${params.email}&confirmationId=${confirmation.confirmationId}`;
    }

    if (params.productType) {
      updatedUrl = `${updatedUrl}&productType=${params.productType}`;
    }

    if (confirmation.customerReferralCode) {
      updatedUrl = `${updatedUrl}&referralCode=${confirmation.customerReferralCode}`;
    }

    // Append scroll puller for Webflow
    updatedUrl = `${updatedUrl}#onboarding-flow`;
    // Redirect to new url
    window.location.href = updatedUrl;
  };

  /**
   * Signal to the mobile UI that we're finished with the onboarding flow.
   */
  handleFinish = async () => {
    window.analytics.track('Onboarding Finish');

    let complete = false;
    if (this.hasSubmissionQuestions) {
      // All questions in the current step have been validated
      const result = await this.actionPanel.current
        .getWrappedInstance()
        .performAction();
      complete = !!result;
    } else {
      complete = true;
    }

    if (complete) {
      // Redirect to 'Thank You!' page
      const path = `${window.location.href.split('#')[0]}#complete`;
      window.location.href = path;

      // We clear history stack to prevent users from navigating to an earlier
      // step in the flow when pressing the browser back button.
      // Otherwise, users may attempt to pay twice and potentially cause issues.
      window.history.replaceState({}, '', path);
    }
  };

  /**
   * Handles back button click and browser history back through the handlers above and below.
   */
  handleBack = backPage => {
    // Refuse to go back if completed
    if (this.state.completed) return;

    // Since we have question jumping enabled, if we don't know where to go back to, we just go to step zero and start there.
    const step = backPage || 0;

    const params = collectQueryParams();
    if (step === 0 && params.device === 'mobile') {
      window.location.href = 'uniwebview://event?eventType=webView&data=close';
    }
    this.setState({activeStep: step, inputFocusOffset: null});

    // Note that we don't update the "start position" query param p on back; only on forward.
    // This is intentional. If a user copies the URL, we keep them at the furthest part of the flow.
    window.analytics.track('Onboarding Back');
    this.mountFlowContainer();
  };

  handleValidation = (questionIdx, isValidated) => {
    const questionValidations = this.state.questionValidations;
    questionValidations[this.state.activeStep][questionIdx] = isValidated;
    this.setState({questionValidations});
    // Save validation as well
    this.props.setQuery({val: this.state.questionValidations});
  };

  onBack = () => {
    // Go through the history if there's state, which triggers this.handleBack()
    if (this.props.history.location.state) window.history.back();
    else this.handleBack();
  };

  onNext = ev => {
    // Make sure page doesn't get refreshed with empty query string!
    ev.preventDefault();
    this.handleNext();
  };

  /**
   * Implements optional interactive onboarding flow branching logic. Gets the next step index based on the current
   * question configuration and data from the query params.
   *
   * This requires that there is currently only one question on the step and that that question be of type
   * - select (single)
   * - text
   * - number
   *
   * For a select question, this checks the question's choices array, which may include a goToStep directive like so:
   *
   *   "choices": [
   *     {"title": "Palm Leaves", "goToStep": 4},
   *     {"title": "Chicken", "goToStep": 2}
   *   ]
   *
   * For a text/number question, checks goToStep: a map from expression to step. The expressions can be
   * a regular expressions like so:
   *
   *   "goToStep": {
   *     "[0-5]$": 2,
   *     "[0-9]+$": 3,
   *     "hello$": 4
   *   }
   *
   * this will
   *   - take the user to step 2 if the answer is 0, 1, 2, 3, 4 or 5,
   *   - take the user to step 3 if the answer is any other number,
   *   - take the user to step 4 if the answer is "hello"
   *   - take the user to the next step if the answer is anything else
   *
   * @returns a number to jump to, if applicable. null if there's no jumping to do.
   */
  getBranchStep = () => {
    const flowSpec = this.props.flowSpec;

    // Grab the interactive questions
    const interactiveQuestions = flowSpec.steps[
      this.state.activeStep
    ].questions.filter(q => q.type && q.type !== OnboardingFlowType.body);

    if (interactiveQuestions.length < 1) {
      return null;
    }

    const question = interactiveQuestions[0];
    if (question.type === OnboardingFlowType.goto) {
      return question.goToStep;
    }

    if (interactiveQuestions.length > 1) {
      return null;
    }

    if (
      question.type === OnboardingFlowType.text ||
      question.type === OnboardingFlowType.number ||
      question.type === OnboardingFlowType.location
    ) {
      const stepMap = question.goToStep;
      if (!stepMap) return null;

      // Only pull out the query params once we know we need to
      const value = collectQueryParams()[question.field];

      for (const [regexp, jumpStep] of Object.entries(stepMap)) {
        const re = new RegExp(regexp);
        if (value.match(re)) return jumpStep;
      }
    } else if (question.type === OnboardingFlowType.select) {
      if (!question.choices.some(choice => choice.goToStep !== undefined))
        return null;

      // Only pull out the query params once we know we need to
      const value = collectQueryParams()[question.field];

      for (const choice of question.choices) {
        if (choice.title === value)
          return choice.goToStep !== undefined ? choice.goToStep : null;
      }
    }

    if (question.type === OnboardingFlowType.package) {
      const choicesWithGoToStep = question.choices.filter(
        choice => choice.goToStep !== undefined,
      );
      if (choicesWithGoToStep.length < 1) {
        return null;
      }

      const questionField = 'priceId';
      const value = collectQueryParams()[questionField];

      for (const choice of question.choices) {
        if (choice?.priceId === value) {
          return choice?.goToStep || null;
        }
      }
    }

    return null;
  };

  onFocusInput = event => {
    // Try to get the offset when an element is in focus to allow our navigators
    // to adjust for mobile browser insanity
    // https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
    const offset = event?.target?.getBoundingClientRect().y;
    this.setState({inputFocusOffset: offset});
  };

  onBlurInput = event => {
    this.setState({inputFocusOffset: null});
  };

  renderStep(activeStep, locale) {
    const step = this.props.flowSpec.steps[activeStep];
    const {classes, flowSpec} = this.props;

    const activeStepDetails = flowSpec.steps[this.state.activeStep];

    const isPaymentPanel = activeStepDetails.questions?.find(
      item => item.type === OnboardingFlowType.payment,
    );

    // Questions can have a number of static content properties
    // - title
    // - body
    // - subtitle
    // - image
    // - video
    // and one interactive "type", which can render into input types
    // or fancier subcomponents.

    return step.questions.map((question, idx) => {
      // Resolve {{queryParam}} embeds from question strings.
      const [title, body, subtitle] = resolveQueryStrings(
        [question.title, question.body, question.subtitle],
        locale,
      );

      return (
        // 12-column grid, so xs=12 is full width
        <Grid
          key={`${activeStep}-${idx}`}
          container
          className={classes.gridContainer}
          direction="row"
          justifyContent="center">
          {title && (
            <Grid
              item
              xs={12}
              className={clsx(
                classes.titleContainer,
                classes.paymentTitleContainer,
              )}>
              <Box>
                <Typography
                  component="h2"
                  variant="h6"
                  gutterBottom
                  className={clsx(
                    classes.questionTitle,
                    isPaymentPanel && classes.paymentPanelTitle,
                  )}>
                  {title}
                </Typography>
              </Box>
            </Grid>
          )}
          {question.type && (
            <Grid item xs={12}>
              <QuestionTypeField
                question={question}
                idx={idx}
                flowName={this.props.flowName}
                activeStep={this.state.activeStep}
                actionPanel={this.actionPanel}
                flowSpecSteps={this.props.flowSpec.steps}
                handleConfirm={this.handleConfirm}
                handleNext={this.handleNext}
                goToNextStep={this.goToNextStep}
                handleValidation={this.handleValidation}
                onFocusInput={this.onFocusInput}
                onBlurInput={this.onBlurInput}
              />
            </Grid>
          )}
          {question.image && (
            <Grid item xs={12}>
              <Image question={question} />
            </Grid>
          )}
          {body && (
            <Grid
              item
              xs={12}
              sm={9}
              style={{textAlign: question.textAlign || 'center'}}>
              {body.length > 400 ? (
                <Paper
                  style={{maxHeight: 300, overflow: 'auto'}}
                  sx={{padding: 3}}>
                  <ReactMarkdown
                    allowDangerousHtml={true}
                    rehypePlugins={[rehypeRaw]}
                    children={body}
                    className={classes.markDownStyles}
                  />
                </Paper>
              ) : (
                <ReactMarkdown
                  allowDangerousHtml={true}
                  rehypePlugins={[rehypeRaw]}
                  children={body}
                  className={classes.markDownStyles}
                />
              )}
            </Grid>
          )}
          {subtitle && (
            <Grid item sm={7} xs={11}>
              <Typography variant="caption" component="p">
                {subtitle}
              </Typography>
            </Grid>
          )}
          {/* We might still need to style this a bit */}
          {question.video && (
            <iframe
              title={question.video}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              src={`https://www.youtube.com/embed/${question.video}`}
            />
          )}
        </Grid>
      );
    });
  }

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({errorVisible: false});
  };

  handleClose = () => {
    this.setState({loading: false});
  };

  render() {
    const {classes, flowSpec} = this.props;

    const activeStepDetails = flowSpec.steps[this.state.activeStep];

    const isPaymentPanel = activeStepDetails.questions?.find(
      item => item.type === 'payment',
    );

    const isLandingPage = activeStepDetails.questions?.find(
      item => item.type === 'landingPage',
    );

    const hideNextButton =
      activeStepDetails.nextButtonCTA ||
      activeStepDetails.nextButtonTitle === 'Pay' ||
      isPaymentPanel ||
      isLandingPage;

    const isMobile = collectQueryParams()['device'] === 'mobile';
    const isFirstStep = this.state.activeStep === 0;
    const hideBackButton = isMobile ? false : isFirstStep;

    const progress =
      (this.state.activeStep / (flowSpec.steps.length - 1)) * 100;

    return (
      <main className={classes.layout}>
        <form onSubmit={this.onNext}>
          <LocalizationContext.Consumer>
            {({locale}) => {
              let stepTitles;
              if (flowSpec.stepTitles.length > 1) {
                stepTitles = resolveQueryStrings(flowSpec.stepTitles, locale);
              }

              if (window.location.href.includes('#complete'))
                return (
                  <Paper className={classes.paper}>
                    <Box className={classes.thankYouBox}>
                      <Typography variant="h4">
                        {STRINGS[locale].thankYou}
                      </Typography>
                    </Box>
                  </Paper>
                );

              return (
                // Outermost wrapper is the Paper
                <Paper className={classes.paper}>
                  {!activeStepDetails.hideStepper && stepTitles && (
                    <>
                      {this.state.isDesktop ? (
                        <Stepper
                          activeStep={
                            // Confirmation screen always renders with all steps checked
                            this.props.query.confirmationId
                              ? stepTitles.length
                              : activeStepDetails.step
                          }
                          classes={{root: classes.stepper}}
                          connector={<PaddingConnector />}>
                          {stepTitles.map((stepTitle, stepIdx) => (
                            <Step key={stepIdx}>
                              <StepLabel>{stepTitle}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      ) : (
                        <Box className={classes.mobileStepperContainer}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            className={classes.stepDetailsContainer}>
                            <Avatar className={classes.mobileStepNumber}>
                              {activeStepDetails.step + 1}
                            </Avatar>
                            <Typography
                              className={classes.stepTitle}
                              component="span">
                              {[stepTitles[activeStepDetails.step]]}
                            </Typography>
                          </Box>

                          <BorderLinearProgress
                            value={flowSpec.steps.length > 1 ? progress : 100}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  {/* If we're not on the confirmation screen, we can render the current step title here on mobile. */}
                  {activeStepDetails.alwaysShowStepTitle &&
                    !this.props.query.confirmationId && (
                      <Typography color="primary" className={classes.stepLabel}>
                        {
                          resolveQueryStrings(
                            [flowSpec.stepTitles[activeStepDetails.step]],
                            locale,
                          )[0]
                        }
                      </Typography>
                    )}

                  <Box
                    component="div"
                    className={classes.flowContainer}
                    ref={this.flowContainer}
                    display="flex"
                    flexDirection="column"
                    style={
                      this.props.maxHeight
                        ? {maxHeight: this.props.maxHeight}
                        : {}
                    }>
                    {!activeStepDetails.hideBackButton && !hideBackButton && (
                      <Box className={classes.backButtonContainer}>
                        <OnboardingBackButton onBack={this.onBack} />
                      </Box>
                    )}
                    <Box component="div" className={classes.questionContainer}>
                      {this.renderStep(this.state.activeStep, locale)}

                      {/* If we're rendering the next button in CTA mode, it's rendered here */}
                      {activeStepDetails.nextButtonCTA && (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            !this.state.questionValidations[
                              this.state.activeStep
                            ].every(isValid => !!isValid)
                          }
                          disableElevation
                          type="submit"
                          onClick={ev => {
                            // Make sure page doesn't get refreshed with empty query string!
                            ev.preventDefault();
                            this.handleNext();
                          }}
                          className={classes.ctaNextButton}>
                          {
                            resolveQueryStrings(
                              [activeStepDetails.nextButtonTitle],
                              locale,
                            )[0]
                          }
                        </Button>
                      )}
                    </Box>
                  </Box>
                  {!activeStepDetails.hideNextButton && (
                    <OnboardingNavigator
                      inputFocusOffset={this.state.inputFocusOffset}
                      onBack={this.onBack}
                      // Note that we don't want to use onNext here, since that would cancel the
                      // event (meant only to prevent form submission) and cause blur/click
                      // cancellation issues.
                      onNext={this.handleNext}
                      onFinish={this.handleFinish}
                      hideNextButton={hideNextButton}
                      disableNextButton={
                        !this.state.questionValidations[
                          this.state.activeStep
                        ].every(isValid => !!isValid)
                      }
                      hideBackButton={hideBackButton}
                      nextButtonTitle={activeStepDetails.nextButtonTitle}
                      variant={
                        this.state.activeStep === flowSpec.steps.length - 1
                          ? this.hasSubmissionQuestions
                            ? 'submit'
                            : 'finish'
                          : 'step'
                      }
                    />
                  )}
                </Paper>
              );
            }}
          </LocalizationContext.Consumer>
        </form>
        <>
          <Backdrop
            className={classes.backdrop}
            open={this.state.loading}
            onClick={this.handleClose}>
            <CircularProgress color="primary" />
          </Backdrop>

          <Snackbar
            open={this.state.errorVisible}
            onClose={this.handleErrorClose}>
            <Alert onClose={this.handleErrorClose} severity="error">
              {this.state.error}
            </Alert>
          </Snackbar>
        </>
      </main>
    );
  }
}

const PaddingConnector = withStyles(theme => ({
  [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
    active: {
      '& $line': {
        // Desktop config
        height: '60px',
        border: 0,
        backgroundColor: palette.blue500,
      },
    },
    completed: {
      '& $line': {
        height: '60px',
        border: 0,
        backgroundColor: palette.blue500,
      },
    },
    line: {
      height: 0,
      border: 0,
    },
  },
}))(StepConnector);

export default withRouter(
  withStyles(styles)(withQueryParams(queryParams, withApollo(OnboardingPanel))),
);
