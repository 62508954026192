enum AnalyticsEvent {
  LevelAdjustmentStarted = 'LevelAdjustment Started',
  LevelAdjustmentCompleted = 'LevelAdjustment Completed',
  ShareLinkCopied = 'ShareLink Copied',
  ReferalLinkCopied = 'ReferalLink Copied',
}

export enum ShareMethod {
  CopyToClipboard = 'CopyToClipboard',
  NativeShare = 'NativeShare',
}

const context = 'Customer portal';
function trackLevelAdjustmentStarted(profileId: string): void {
  analyticsTrack(AnalyticsEvent.LevelAdjustmentStarted, {
    profileId,
    context,
  });
}

function trackLevelAdjustmentCompleted(
  profileId: string,
  adjustmentRequest: string,
): void {
  analyticsTrack(AnalyticsEvent.LevelAdjustmentCompleted, {
    profileId,
    adjustmentRequest,
    context,
  });
}

function trackCopiedShareLink(shareMethod: ShareMethod): void {
  analyticsTrack(AnalyticsEvent.ShareLinkCopied, {
    shareMethod,
  });
}

function trackReferalLinkCopied(shareMethod: ShareMethod): void {
  analyticsTrack(AnalyticsEvent.ReferalLinkCopied, {
    shareMethod,
  });
}

function analyticsTrack(event: string, properties: Record<string, unknown>) {
  window.analytics.track(event, properties);
}

const AnalyticsService = {
  trackCopiedShareLink,
  trackLevelAdjustmentCompleted,
  trackLevelAdjustmentStarted,
  trackReferalLinkCopied,
};

export default AnalyticsService;
