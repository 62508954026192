import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const TrialCompliance = ({classes, priceString, trialPeriodDays}) => {
  const endDate = moment().add(trialPeriodDays, 'days').format('MMM D YYYY');

  return (
    <Box mt={1} mb={1}>
      <Box>
        <Typography
          align="left"
          component="p"
          variant="caption"
          className={classes.subscriptionDetails}>
          {`Cancel any time. You will be charged $${priceString} on ${endDate} when your trial ends. Sales tax may apply.`}
        </Typography>
      </Box>
    </Box>
  );
};

export default TrialCompliance;
