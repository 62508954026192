/**
 * Helper function to get a complete set of query params.
 */
export function collectQueryParams() {
  const iterable = new URLSearchParams(window.location.search).entries();

  const params = {};

  for (const pair of iterable) {
    // Set key and value of params dict
    params[pair[0]] = pair[1];
  }

  return params;
}
