import {cancellationFeedback} from './types';

export function cancelSubscriptionWithMutation(
  cancelSubscription: (options: any) => Promise<any>,
) {
  return async (input: cancellationFeedback) => {
    try {
      const {data} = await cancelSubscription({
        variables: {
          input,
        },
      });
      return data;
    } catch (error) {
      console.log('cancel subscription:', error);
      return;
    }
  };
}
